import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: () => import('../views/Home.vue')
    // redirect: "/home"
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      scrollToTop: true
    },
    component: () => import('../views/Home.vue')
    
  },
   {
    path: '/detail',
    name: 'detail',
    meta: {
      scrollToTop: true
    },
    component: () => import('@/views/detail.vue'),
  },
  {
    path: '/detailStand',
    name: 'detailStand',
    meta: {
      scrollToTop: true
    },
    component: () => import('@/views/detailStand.vue'),
	},
	{
	  path: '/Home2',
	  name: 'Home2',
	  meta: {
	    scrollToTop: true
	  },
	  component: () => import('@/views/Home2.vue'),
		},
	{
	  path: '/detailEmploy',
	  name: 'detailEmploy',
	  meta: {
	    scrollToTop: true
	  },
	  component: () => import('@/views/detailEmploy.vue'),
  },
  {
    path: '/detailPeople',
    name: 'detailPeople',
    meta: {
      scrollToTop: true
    },
    component: () => import('@/views/detailPeople.vue'),
  },
  {
    path: '/detailService',
    name: 'detailService',
    meta: {
      scrollToTop: true
    },
    component: () => import('@/views/detailService.vue'),
  },
  {
    path: '/questionnaire',
    name: 'questionnaire',
    meta: {
      scrollToTop: true
    },
    component: () => import('@/views/questionnaire.vue'),
  },
  {
    path: '/HomeChemicalPeopleOrService',
    name: 'HomeChemicalPeopleOrService',
    meta: {
      scrollToTop: true
    },
    component: () => import('@/views/HomeChemicalPeopleOrService.vue'),
  },
]

//路由导航冗余报错（路由重复）
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
