<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
export default {
  components: {},
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}
::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #0678be;
  border-radius: 5px;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.el-popper {
  background: rgba(255, 255, 255, 0.1) !important;
  filter: blur(0.1px); /* Chrome, Opera */
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  border: 0 !important;
}
</style>
