/*
 * @Description: 
 * @Author: charles
 * @Date: 2020-10-26 17:04:14
 * @LastEditors: charles
 * @LastEditTime: 2021-08-01 13:57:17
 */
import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
import qs from 'querystring'
// create an axios instance
const service = axios.create({
  // baseURL:'http://58.208.60.153:8090/form',
  // baseURL:'http://121.229.102.50:9400',//本地测试
  // baseURL:'http://106.14.46.100:9400',//线上地址
   baseURL:'http://www.jsclouds.com/form',//线上地址-新
  // baseURL:'http://61.147.209.243:9400',
    //baseURL:'https://www.ntciounion.com/form',
  timeout: 5000 // request timeout
})
// http请求拦截
service.interceptors.request.use(
  function (config) {
    config.headers.Authorization = localStorage.token;
    config.headers.AccessControlAllowOrigin = "*";
    return config;
  },
  function (error) {
    // 请求失败的处理
    return Promise.reject(error);
  }
);
// response interceptor
// 3.响应拦截器
service.interceptors.response.use(
  (response) => {
    // 接收到响应数据并成功后的一些共有的处理，关闭loading等
    return response;
  },
  (error) => {
    /** *** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      switch (error.response.status) {
        case 300:
          //   // Toast("身份认证信息已失效，请重新登录。");
          //   localStorage.token = null;
          //   vm.$router.push({ name: "login" });
          //   break;
          error.message = "错误请求";
          break;
        case 400:
          error.message = "错误请求";
          break;
        case 401:
          Toast("身份认证信息已失效，请重新登录。");
          localStorage.token = null;
          // vm.$router.push({ name: "login" });
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = "请求错误,未找到该资源";
          // window.location.href = "/NotFound";
          break;
        case 405:
          error.message = "请求方法未允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器端出错";
          break;
        case 501:
          error.message = "网络未实现";
          break;
        case 502:
          error.message = "网络错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网络超时";
          break;
        case 505:
          error.message = "http版本不支持该请求";
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes("timeout")) {
        // console.log('服务器响应超时，请刷新当前页')
      }
      error.message = "连接服务器失败";
    }

    console.error(error.message);
    /** *** 处理结束 *****/
    return Promise.resolve(error.response);
  }
);
export function get(url, params) {
  return service.get(url, {
    params, // get 请求时带的参数
    timeout: 10000,
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  })
}
export function del(url, params) {
  return service.delete(url, {
    params, // get 请求时带的参数
    timeout: 10000,
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  })
}

// 自定义post
export function post(url, data) {
  return service.post(url, data, {
    timeout: 10000,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json; charset=UTF-8'
    }
  })
}

// 退出
async function logout() {
  router.push(`/login`)
}

export default service
